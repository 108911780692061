import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        position: relative;
        display: ${block ? 'block' : 'inline-flex'};
        z-index: 900;

        &:hover {
            z-index: 910;
        }
    `}
`;

export const Trigger = styled.div.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        display: ${block ? 'block' : 'inline-flex'};
        padding-left: 1rem;
        margin-left: -1rem;
    `}
`;

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['position'].includes(prop),
})<{
    position?: 'bottomLeft' | 'bottom' | 'bottomRight' | 'topLeft' | 'top' | 'topRight';
}>`
    ${({ theme, position }) => css`
        position: absolute;
        display: none;
        white-space: nowrap;

        ${Wrapper}:hover & {
            display: block;
        }

        ${position === 'bottomLeft' &&
        css`
            top: 100%;
            left: 0;
            padding-top: ${theme.gap.xsmall};
        `}

        ${position === 'bottom' &&
        css`
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding-top: ${theme.gap.xsmall};
        `}

        ${position === 'bottomRight' &&
        css`
            top: 100%;
            right: 0;
            padding-top: ${theme.gap.xsmall};
        `}

        ${position === 'topLeft' &&
        css`
            bottom: 100%;
            left: 0;
            padding-bottom: ${theme.gap.xsmall};
        `}

        ${position === 'top' &&
        css`
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding-bottom: ${theme.gap.xsmall};
        `}

        ${position === 'topRight' &&
        css`
            bottom: 100%;
            right: 0;
            padding-bottom: ${theme.gap.xsmall};
        `}
    `}
`;

export const ContentInner = styled.div`
    max-height: calc(100vh - 15rem);
    overflow: auto;
    padding: 1.5rem;
`;
